.steps-list{
    display: flex;
    height: 45px;
    margin-bottom: 40px;
    padding: 0;
    width: 90%;
    min-width: 700px;
    li{
        margin-right: 18px;
        list-style: none;
        margin-left: 0;
        line-height: unset;
        width: 20%;
        min-height: 45px;
        a{
            background: #FFFFFF;
            border: 1px solid #DEDEDE;
            box-sizing: border-box;
            background-color: white;
            padding: 12px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 45px;
            input{
                color: #757575;
            }
            &.active{
                background: #00C87F;
                color: #00C87F !important;
                border-color: #00C87F !important;
                input{
                    color: white !important;
                }
            }
            &:hover{
                input{
                    color: black;
                }
                border-color: black;
            }
        }
    }
}